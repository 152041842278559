import { composeApp } from 'shared/app/shell';
import GlobalNav from 'shared/app/components/dotcom-global-nav';
import userBundle from 'shared/app/bundles/user';
import scriptsBundle from 'shared/app/bundles/scripts';
import viewportBundle from 'shared/app/bundles/viewport';
import geolocationBundle from 'shared/app/bundles/geolocation';

import storeLocator from './store-locator-subapp.js';

export default composeApp({
  nav: {
    globalNav: {
      component: GlobalNav,
    },
  },
  apps: [
    viewportBundle,
    scriptsBundle,
    geolocationBundle,
    storeLocator,
    userBundle,
  ],
});
